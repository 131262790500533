import React from "react";

import ContactFromDate from "data/sections/form-info.json";

const ContactForm = () => {
  
  return (
    <section className="contact section-padding">
      <div className="container">
      <div className="row justify-content-md-center">
          <div className="col-lg-5 offset-lg-1">
            <div className="cont-info">
              <h4 className="fw-700 color-font mb-50">Contact Info.</h4>
              <h3 className="wow" data-splitting>
                {ContactFromDate.title}
              </h3>
              <div className="item mb-40">
                <h5>
                  <a href="#0">{ContactFromDate.email}</a>
                </h5>
              
              </div>
              
          
           
            </div>
          </div>
        </div>
        </div>
      
    </section>
  );
};

export default ContactForm;
